<template>
  <div class="UserDetail">
    <div v-if="getUser">
      <div class="UserDetailBack" @click="goToUserlList">
        <ChevronLeft :size="20" />
        <span class="UserDetailBackText">Back to user list</span>
      </div>
      <div class="UserDetailTitle">User information:</div>
      <div class="UserDetailRow">User name: {{ getUser.name }}</div>
      <div class="UserDetailRow">User last name: {{ getUser.last_name }}</div>
      <div class="UserDetailRow">User phone: {{ getUser.phone }}</div>
      <div class="UserDetailRow">User email: {{ getUser.email }}</div>

      <div class="UserDetailTitle">User options:</div>
      <div
        class="UserDetailRow"
        v-if="UserStatusEnums.Verified === getUser.status"
      >
        <span>Login to guest:</span>
        <LoginVariant :size="20" @click="loginAs(getUser.id)"></LoginVariant>
      </div>
      <div class="UserDetailRow">
        <span>User verified:</span>
        <ShieldCheck
          :size="20"
          title="Verified"
          v-if="getUser.status === UserStatusEnums.Verified"
        />
        <ShieldOff
          v-else
          :size="20"
          title="Click for verify"
          @click="verifyUser(getUser.id)"
        />
      </div>
      <div class="UserDetailRow">
        <span>User reception status:</span>
        <AccountTieVoice
          :size="20"
          v-if="getUser.is_reception"
          @click="toggleManagerUser(getUser.id)"
        /><AccountTieVoiceOff
          :size="20"
          v-else
          @click="toggleManagerUser(getUser.id)"
        />
      </div>
      <div class="UserDetailRow">
        <span>User manager status:</span>
        <AccountTieVoice
          :size="20"
          v-if="getUser.is_manager"
          @click="toggleManagerUser(getUser.id)"
        /><AccountTieVoiceOff
          :size="20"
          v-else
          @click="toggleManagerUser(getUser.id)"
        />
      </div>
      <div class="UserDetailRow">
        <span>User blocked:</span>
        <Lock
          :size="20"
          @click="toggleBlockUser(getUser.id)"
          v-if="getUser.is_blocked"
        ></Lock>
        <LockOpen
          :size="20"
          @click="toggleBlockUser(getUser.id)"
          v-else
        ></LockOpen>
      </div>
        <div class="UserDetailRow">
            <span>User agency:</span>
            <Lock
                    :size="20"
                    @click="toggleAgencyUser(getUser)"
                    v-if="getUser.is_agency"
            ></Lock>
            <LockOpen
                    :size="20"
                    @click="toggleAgencyUser(getUser)"
                    v-else
            ></LockOpen>
        </div>
      <div class="UserDetailRow">
        <span>Edit user:</span>
        <Pencil :size="20" @click="changeItem(getUser)"></Pencil>
      </div>
      <div class="UserDetailRow">
        <span>Delete user:</span>
        <Delete :size="20" @click="showDeleteModal(getUser.id)"></Delete>
      </div>

      <div class="UserDetailTitle">User hotels:</div>
      <div
        class="UserDetailRow"
        v-for="hotel in getUser.hotels"
        :key="hotel.id"
        @click="openHotel(hotel.id)"
      >
        Open hotel: {{ hotel.name }}
      </div>
    </div>

    <div v-else>User with ID: {{ $route.params.user_id }} not found.</div>
    <UserChangeModal
      :show="show"
      :item="getUser"
      @close="closeModal"
      @accept="editItem"
    />
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
  </div>
</template>

<script>
import { userModule } from "../store/modules/userModule";
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import LoginVariant from "vue-material-design-icons/LoginVariant.vue";
import ShieldCheck from "vue-material-design-icons/ShieldCheck.vue";
import ShieldOff from "vue-material-design-icons/ShieldOff.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import UserChangeModal from "@/components/modals/UserChangeModal";
import { UserStatusEnums } from "@/enums/UserStatusEnums";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import Lock from "vue-material-design-icons/Lock.vue";
import LockOpen from "vue-material-design-icons/LockOpen.vue";
import AccountTieVoice from "vue-material-design-icons/AccountTieVoice.vue";
import AccountTieVoiceOff from "vue-material-design-icons/AccountTieVoiceOff.vue";
export default {
  name: "UserDetailComponent",
  data() {
    return {
      show: false,
      showDelete: false,
      deleteItemID: "",
      UserStatusEnums: UserStatusEnums,
    };
  },
  computed: {
    ...userModule.mapGetters(["getUser", "getUserListData"]),
  },
  components: {
    UserChangeModal,
    ChevronLeft,
    Delete,
    LoginVariant,
    Pencil,
    DeleteConfirmModal,
    ShieldCheck,
    ShieldOff,
    Lock,
    LockOpen,
    AccountTieVoice,
    AccountTieVoiceOff,
  },
  methods: {
    ...userModule.mapActions([
      "getUserList",
      "deleteUser",
      "loginAsUser",
      "editUser",
      "verifyUser",
      "toggleBlockUser",
      "toggleManagerUser",
      "toggleReceptionUser",
      "toggleAgencyUser"
    ]),
    ...userModule.mapMutations(["setUser"]),
    openHotel(id) {
      this.$router.push("/hotels/" + id);
    },
    goToUserlList() {
      this.$router.push("/users");
    },
    async deleteItem() {
      await this.deleteUser(this.deleteItemID);
      this.deleteItemID = "";
      this.showDelete = false;
    },
    showDeleteModal(id) {
      this.showDelete = true;
      this.deleteItemID = id;
    },
    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = "";
    },
    async loginAs(id) {
      await this.loginAsUser(id);
    },
    closeModal() {
      this.show = false;
    },
    async editItem() {
      let req = {
        id: this.getUser.id,
        user: this.getUser,
      };
      await this.editUser(req);
      this.showEdit = false;
    },
    changeItem() {
      this.show = true;
    },
  },
  async mounted() {
    if (!this.getUserListData.length) {
      await this.getUserList();
    }
    const candidate = this.getUserListData.find(
      (user) => +user.id === +this.$route.params.user_id
    );
    if (candidate) {
      this.setUser(candidate);
    } else {
      this.setUser(null);
    }
  },
};
</script>

<style scoped lang="scss">
.UserDetail {
  padding: 30px;
  display: flex;
  flex-direction: column;

  &Back {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 150px;
    margin-bottom: 30px;
    &Text {
      margin-top: 2px;
    }
  }

  &Title {
    font-size: 16px;
    font-weight: bold;
  }
  &Row {
    padding-left: 5px;
    display: flex;
    & span {
      display: block;
      width: 150px;
    }
  }
}
</style>
